import '../App.css';
import Navigation from './Navigation';
import Top from './Top';

function Header() {
  return (
    <header>
      <Top />
      <Navigation />
    </header>
  );
}

export default Header;
