import { Container, Row, Col } from "react-bootstrap";
import '../App.css';
import i18n from "../i18n";

function AboutScreen() {
    return (
        <Container className="mt-5 pb-5 mb-5">
            <Row>
                <Col>
                    <img src="/images/gallery/1.jpg" alt="Fishfarm ELAZ" className="about-img" />
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("aboutdesc") }} />
                </Col>
            </Row>
        </Container>
    );
}

export default AboutScreen;
