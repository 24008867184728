import './App.css';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router
} from "react-router-dom";
import CONSTANTS from './CONSTANTS';
import Header from './components/Header';
import Main from './components/Main';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const { i18n } = useTranslation();

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href={CONSTANTS.BOOTSTRAP.href}
        integrity={CONSTANTS.BOOTSTRAP.integrity}
        crossOrigin="anonymous"
      />

      <Router>
        <Header />
        <ScrollToTop />
        <Main />
      </Router>
    </div>
  );
}

export default App;
