import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../App.css';
import i18n from "../i18n";

function NoMatchScreen() {
    return (
        <div className='parallax__wrapper'>
            <Container>
                <Row>
                    <Col className="mt-5 text-center">
                        <h1 className="mt-5">404</h1>
                        <h5 className="mb-5">{i18n.t("nomatch")}</h5>
                        <Link to="/">
                            <button as={Link} className='dark-btn'>
                                {i18n.t("nav.home")}
                            </button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NoMatchScreen;