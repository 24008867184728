import { Link } from 'react-router-dom';
import '../App.css';
import CONSTANTS from '../CONSTANTS';
import i18n from '../i18n';
import NavLink from './NavLink';

const ROUTES = CONSTANTS.ROUTES;

function Navigation() {
  return (
    <nav id='main-nav'>
      <Link to={ROUTES.home.path} className="main-nav-logo">
        <img src="logo192.png" alt="Fishfarm ELAZ logo" />
      </Link>
      <ul>
        <NavLink to={ROUTES.home.path} isBgBlack>
          {i18n.t(ROUTES.home.title)}
        </NavLink>
        <NavLink to={ROUTES.fish.path}>
          {i18n.t(ROUTES.fish.title)}
        </NavLink>
        <NavLink to={ROUTES.about.path}>
          {i18n.t(ROUTES.about.title)}
        </NavLink>
        <NavLink to={ROUTES.projects.path}>
          {i18n.t(ROUTES.projects.title)}
        </NavLink>
        <NavLink to={ROUTES.gallery.path}>
          {i18n.t(ROUTES.gallery.title)}
        </NavLink>
        <NavLink to={ROUTES.contacts.path}>
          {i18n.t(ROUTES.contacts.title)}
        </NavLink>
      </ul>
    </nav>
  );
}

export default Navigation;
