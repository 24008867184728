import '../App.css';
import CONSTANTS from '../CONSTANTS';
import i18n from '../i18n';
import Select from 'react-select'
import { useState } from 'react';

const LANG = CONSTANTS.LANGUAGE;
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'inherit' : 'inherit',
        color: 'black',
        opacity: (state.isSelected || state.isHovered) ? '1' : '0.5',
        transition: 'opacity 0.2s',
        "&:hover": {
            opacity: '1',
            backgroundColor: state.isSelected ? '#fff' : '#eee'
        },
        cursor: 'pointer'
    }),
    control: (provided, state) => ({
        ...provided,
        width: "11.5rem",
        height: '30px',
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
        minHeight: '30px',
        borderRadius: '0',
        cursor: 'pointer'
    }),
    container: (provided, state) => ({
        ...provided,
        height: '25px',
        borderRadius: '0'
    }),
    menu: (provided, state) => ({
        ...provided,
        borderRadius: '0'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '30px'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        opcaity: 1,
        height: '30px'
    }),
}

function Top() {
    const [selected, setSelected] = useState({
        value: i18n.language,
        label: LANG.find(l => l.key === i18n.language).value,
        image: LANG.find(l => l.key === i18n.language).img
    });

    const switchLang = (newLang) => {
        i18n.changeLanguage(newLang).then(() => {
            localStorage.setItem('selectedLanguage', newLang);
            setSelected(LANG.find(l => l.key === newLang));
        });
    };

    function onLanguageChange(option) {
        switchLang(option.value);
    }

    return (
        <div id='header-top'>
            <Select
                options={LANG.map(l => ({
                    value: l.key,
                    label: l.value,
                    image: l.img
                }))}
                onChange={onLanguageChange}
                isSearchable={false}
                defaultValue={selected}
                formatOptionLabel={l => (
                    <div className='lang-option'>
                        <img src={l.image} alt={l.label} width='20px' height='20px' />
                        <span className='ml-3'>{l.label}</span>
                    </div>
                )}
                styles={customStyles}
                className='header-top-select'
            />
        </div>
    );
}

export default Top;
