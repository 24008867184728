import { Container, Row, Col } from 'react-bootstrap';
import { Switch, Route, useLocation } from 'react-router-dom';
import '../App.css';
import CONSTANTS from '../CONSTANTS';
import i18n from "../i18n";

const ROUTES = CONSTANTS.ROUTES;

function SectionHeader() {
    let location = useLocation();

    function displayHeader(title) {
        return (
            <div id="section-header">
                <Container>
                    <Row>
                        <Col className="mt-5">
                            {!location.pathname.includes("/fish/") ? (<div className='title-block' />) : null}
                            <h2 className="mb-4">
                                {title}
                            </h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    return (
        <Switch>
            <Route exact path={ROUTES.home.path}>
                {null}
            </Route>
            <Route exact path={ROUTES.fish.path}>
                {displayHeader(i18n.t(ROUTES.fish.title))}
            </Route>
            <Route exact path={ROUTES.carp.path}>
                {displayHeader(i18n.t(ROUTES.fish.title))}
            </Route>
            <Route exact path={ROUTES.catfish.path}>
                {displayHeader(i18n.t(ROUTES.fish.title))}
            </Route>
            <Route exact path={ROUTES.cupid.path}>
                {displayHeader(i18n.t(ROUTES.cupid.title))}
            </Route>
            <Route exact path={ROUTES.about.path}>
                {displayHeader(i18n.t(ROUTES.about.title))}
            </Route>
            <Route exact path={ROUTES.projects.path}>
                {displayHeader(i18n.t(ROUTES.projects.title))}
            </Route>
            <Route exact path={ROUTES.gallery.path}>
                {displayHeader(i18n.t(ROUTES.gallery.title))}
            </Route>
            <Route exact path={ROUTES.contacts.path}>
                {displayHeader(i18n.t(ROUTES.contacts.title))}
            </Route>
            <Route path="*">
                {null}
            </Route>
        </Switch>
    );
}

export default SectionHeader;
