var CONSTANTS = {

    BOOTSTRAP: {
        href: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css",
        integrity: "sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
    },

    ROUTES: {
        home: {
            key: "home",
            title: "nav.home",
            path: "/"
        },
        fish: {
            key: "fish",
            title: "nav.fish",
            path: "/fish"
        },
        carp: {
            key: "carp",
            title: "nav.carp",
            path: "/fish/carp"
        },
        catfish: {
            key: "catfish",
            title: "nav.catfish",
            path: "/fish/catfish"
        },
        cupid: {
            key: "cupid",
            title: "nav.cupid",
            path: "/fish/cupid"
        },
        about: {
            key: "about",
            title: "nav.about",
            path: "/about"
        },
        projects: {
            key: "projects",
            title: "nav.projects",
            path: "/projects"
        },
        gallery: {
            key: "gallery",
            title: "nav.gallery",
            path: "/gallery"
        },
        contacts: {
            key: "contacts",
            title: "nav.contacts",
            path: "/contacts"
        },
    },
    
    LANGUAGE: [
        {
            key: "bg",
            value: "Български",
            img: "/images/flag_bg.png"
        },
        {
            key: "en",
            value: "English",
            img: "/images/flag_uk.png"
        },
        {
            key: "ro",
            value: "Română",
            img: "/images/flag_ro.png"
        }
    ]
}

export default CONSTANTS;