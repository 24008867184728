import { Container, Row, Col, Card } from "react-bootstrap";
import '../App.css';
import CONSTANTS from "../CONSTANTS";
import i18n from "../i18n";
import { Link } from "react-router-dom";

const ROUTES = CONSTANTS.ROUTES;

function FishScreen() {
    return (
        <Container className="mt-5 pb-5 mb-4">
            <Row>
                <Col xs={12} md={4}>
                    <Card className="fish-item mb-4" as={Link} to={ROUTES.carp.path}>
                        <Card.Img variant="top" src="images/carp.jpg" />
                        <Card.Body>
                            <Card.Title>
                                {i18n.t(ROUTES.carp.title)}
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card className="fish-item mb-4" as={Link} to={ROUTES.cupid.path}>
                        <Card.Img variant="top" src="images/cupid.jpg" />
                        <Card.Body>
                            <Card.Title>
                                {i18n.t(ROUTES.cupid.title)}
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card className="fish-item mb-4" as={Link} to={ROUTES.catfish.path}>
                        <Card.Img variant="top" src="images/catfish.jpg" />
                        <Card.Body>
                            <Card.Title>
                                {i18n.t(ROUTES.catfish.title)}
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default FishScreen;
