import '../App.css';
import { Col, Container, Row } from 'react-bootstrap';

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={2} md={1}>
            <img src="/images/logo.png" alt="Fishfarm ELAZ logo" className='footer-logo' />
          </Col>
          <Col xs={10} md={11}>
            <small className="footer-copyright">
              Copyright &#169; 2022
              <br />
              Fish Farm ELAZ Ltd.
            </small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
