import '../App.css';
import { Col, Container, Row } from 'react-bootstrap';
import i18n from "../i18n";

function ContactsScreen() {
    return (
        <Container className="mt-5 mb-5 content">
            <Row>
                <Col md={6} className="mb-5">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23116.05938542572!2d26.131203568254918!3d42.26187945380212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a7c319dc69117d%3A0xe9e9303090b606c2!2sFish%20Farm%20ELAZ!5e0!3m2!1sen!2sbg!4v1645521728515!5m2!1sen!2sbg" id="map-contacts" allowFullScreen={true} loading="lazy" title="Map showing location of Fish Farm ELAZ"></iframe>
                </Col>
                <Col md={6}>
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("contactsdesc") }} />
                </Col>
            </Row>
        </Container>
    );
}

export default ContactsScreen;
