import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));

setLanguage();
supressWarning();

root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
);

function setLanguage() {
  var lang = localStorage.getItem('selectedLanguage');

  if (lang && lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }
}

function supressWarning() {
  const backup = console.warn;

  console.warn = function filterWarnings(msg) {
    const supressedWarnings = ['\'i18n\' is assigned a value but never used  no-unused-vars'];

    if (!supressedWarnings.some(entry => msg.includes(entry))) {
      backup.apply(console, arguments);
    }
  };
}