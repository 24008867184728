import { Container, Row, Col, Image } from "react-bootstrap";
import '../App.css';
import CONSTANTS from "../CONSTANTS";
import i18n from "../i18n";
import { Link } from "react-router-dom";

const ROUTES = CONSTANTS.ROUTES;

function Catfish() {
    return (
        <Container className="pb-5">
            <Row>
                <Col>
                    <Link to={ROUTES.fish.path}>
                        <button className='dark-btn'>
                            {"<< " + i18n.t("back")}
                        </button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h2 className="mt-3 mb-4">{i18n.t(ROUTES.catfish.title)}</h2>
                </Col>
                <Col xs={12} md={6}>
                    <Image fluid src="/images/catfish.jpg" className="fish-img" />
                </Col>
                <Col xs={12} md={6}>
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("catfishdesc") }} />
                </Col>
            </Row>
        </Container>
    );
}

export default Catfish;
