import { Container, Row, Col, Image } from "react-bootstrap";
import '../App.css';
import i18n from "../i18n";

function ProjectsScreen() {
    return (
        <Container className="mt-5 pb-5">
            <Row>
                <Col xs={12} className="mb-5">
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc1") }} />
                </Col>
                <Col xs={12} className="pt-5 mb-5">
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc2") }} />
                </Col>
                <Col xs={12} className="pt-5 mb-5">
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc3") }} />
                </Col>
                <Col xs={12} className="pt-5 mb-5">
                    <div dangerouslySetInnerHTML={{ __html: i18n.t("projectsdesc4") }} />
                </Col>
            </Row>
        </Container>
    );
}

export default ProjectsScreen;
